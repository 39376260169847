.site-card__img {
  width: 40px;
  height: 40px;
}

.site-card__amount {
  background: #f9f6fc;
  font-size: 18px;
  font-weight: 510;
}

. {
  color: #595959;
}

.details-btn__card {
}

.details-btn__card-text {
  text-align: center;
  color: #040404;
  background: #f5edff;
  /* color:  */
}
