.column-input__cover {
  /* border: 1px solid #6418c2; */
  background-color: #6518c203;
  font-size: 13px;
  overflow: hidden;
  height: 35px;
}

.column-input {
  width: 75%;
  border: none !important;
  outline: none !important;
  height: 100%;
}

.column-input:focus {
  outline: none !important;
}
.column-btn {
  width: 25%;
  height: 100%;
}

.column-input::placeholder {
  /* outline: 1px solid #6518c293; */
  font-size: 13px;
}

.customer-title {
  font-size: 14px;
}
.customer-caption {
  font-size: 13px;
}

.column-btn {
  /* background-color: #6418c2; */
  font-size: 13px;
  cursor: pointer;
  height: 33px;
}
