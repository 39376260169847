.control-select {
  background: #f5ebff;
  width: 100%;
  height: 40px;
}

.control-btn {
  width: 100px;
  font-size: 12px;
}
.control-caption {
  color: #707070;
}
