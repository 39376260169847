.dpqc {
  height: auto;
}

.pqdfbtn {
  width: 33%;
  font-size: 11px;
  cursor: pointer;
  font-weight: 400;
}
