.tab-title {
  font-size: 14px;
}

.tabBtncover {
  width: 100px;
}

.customer-search__input {
  outline: none;
  border: none !important;
  height: 100%;
  font-size: 14px;
}

.customer-search__input:focus {
  outline: none;
  border-bottom: 1px solid #e8daf9;
}

.customer-search__input::placeholder {
  font-size: 13px;
}
