.advanced-filter__cover {
  min-width: 300px;
  max-width: 600px;
  width: 400px;
  height: auto;
}

.search-btn {
  cursor: pointer;
  transition: 10ms all;
}

.search-btn:hover {
  outline: 2px solid #cdb9fb;
}

.search-title {
  /* color: #0184d020; */
  font-size: 15px;
  /* color: #00800010; */
}
