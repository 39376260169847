.cardtitle {
  font-size: 17px;
}
.cardcaption {
  color: #707070;
  /* font-weight: 300; */
}

.input-entry {
  width: 100%;
}
.input-entry::placeholder {
  font-weight: 300;
  height: 100%;
  font-size: 15px;
}

.input-box {
  outline: none !important;
  height: 40px;
  border: 1px solid #d7d7d7 !important;
  background-color: #f7f4fa;
}

.addrolebtn-cover {
  border-top: 1px solid #eeee;
}

.addrole-btn {
  width: 45%;
}

.addrole-img {
  width: 40px;
  height: 40px;
}
