.stats-cover {
  border: 1px solid #ffbd5e;
}

.stats-img_cover {
  width: 30px;
  height: 30px;
}

.stats-value {
  font-size: 20px;
  font-weight: 600;
}

.stats-caption {
  font-size: 17px;
  font-weight: 400;
  color: #cacaca;
  margin-top: -5px;
}

.stats-card__title {
  font-size: 400;
  font-size: 17px;
}
