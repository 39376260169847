.revenue-date__cover {
  /* background-color: ; */
  font-size: 12px;
  width: 32%;
}

.revenue-chart__cover {
  width: 100%;
}

.revenue-amount {
  font-size: 20px;
  font-weight: 600;
}
