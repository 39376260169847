.hashbg {
  width: 40px;
  height: 40px;
}

.role-card {
  border-bottom: 1px solid #eee;
}

.role-name {
  color: #040404;
  font-size: 1rem;
}

.role-caption {
  color: #9f9e9e;
  font-weight: 400 !important;
  font-size: 13px;
}

.role-date {
  color: #9f9e9e;
  font-size: 13px;
  font-weight: 400 !important;
}
