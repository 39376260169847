@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&family=Kanit:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap");

html,
body {
  height: 100%;
}

.leaflet-container {
  height: 100%;
  width: 100%;
}

/* you can have custom dimension according to your use case */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
  color: #dceef9;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield !important;
}

body {
  background: #f7f7f7 !important;
}

.backdrop {
  width: 100%;
  height: 100vh;
  position: fixed;
  background: #0000007a;
  z-index: 100000 !important;
  top: 0 !important;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  left: 0px;
  align-items: center;
  justify-content: center;
}

::-webkit-scrollbar {
  width: 0 !important;
}

/* .ant-tabs-ink-bar {
  background: black !important;
} */
