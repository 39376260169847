.topcard-cover {
  height: 100px;
}
.topcard-title {
  color: #878787;
}

.topcard-value {
  font-size: 25px;
  font-weight: 600;
  margin-top: -5px;
}

.topcard-right__cover {
  width: 50px;
  height: 45px;
}
