.meter-info__modal {
  width: 400px;
}

.meter-info-title {
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
}

.meter-info__top {
  border-bottom: 1px solid #eee;
}

.meter-info__title {
  color: #828282;
  font-weight: 400;
  font-size: 13px;
}

.meter-info__section {
  border-top: 1px solid #eee;
}
